import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { sortArray } from 'magmel';

// components
import { ShootPage, SectionHeader } from 'src/components/Photography';
import { Navbar, Banner, Footer } from 'src/components/common';

const EventPage = () => {
  return (
    <>
      <ShootPage
        style={{
          marginTop: '100px',
        }}
      >
        <Navbar dark />
        <div className="container-lg">
          <SectionHeader sectionName="Innovative Micro Finance" />
        </div>
        <Grid />
        <div className="write-up container-lg">
          <p>
            The people of the Northern Region are rich in culture, they understand family and unity
            and more importantly they seamlessly fuse happiness and work. In this documentary for
            Innovative Microfinance we explore how the 'Sun Never Sets On Happiness' in Tamale and
            Bolgatanga.
          </p>
        </div>
      </ShootPage>

      <Banner />
      <Footer />
    </>
  );
};

const Grid = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(
            filter: {
              relativePath: { regex: "/photography/documentaries/innovative-microfinance/" }
            }
          ) {
            nodes {
              name
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                  originalImg
                }
              }
            }
          }
        }
      `}
      render={({ allFile }) => {
        const sortedArray = sortArray({
          array: allFile.nodes,
          order: 'asce',
          key: 'name',
        });

        return (
          <div className="horizontal-grid">
            <a class="right-arrow">
              <svg width={25} height={25} viewBox="0 0 512 512">
                <path
                  fill="none"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48px"
                  d="M268 112L412 256 268 400"
                />
                <path
                  fill="none"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48px"
                  d="M392 256L100 256"
                />
              </svg>
            </a>
            <div className="horizontal-full hs">
              {sortedArray.map((file, index) => {
                return (
                  <Img
                    className="horizontal-image portrait"
                    fluid={file.childImageSharp.fluid}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
        );
      }}
    />
  );
};

export default EventPage;
